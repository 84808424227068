import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import Aos from 'aos';

import 'aos/dist/aos.css';
import './assets/css/General.css';
import './assets/css/bootstrap.min.css';
import './assets/css/responsive.css';

import 'bootstrap/dist/js/bootstrap.bundle.min';
import HomePage from './pages/HomeDemo1';

const App = () => {
	useEffect(() => {
		Aos.init({
			duration: 1000
		});
	}, []);

	return (
		<div className='App'>
			<Helmet>
				<meta charset='utf-8' />
				<meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no' />
				<title>NFT Marketplace</title>
				<link href='https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700' rel='stylesheet' />
			</Helmet>
			<Switch>
				<Route path='/' exact component={HomePage} />
			</Switch>
		</div>
	);
};

export default App;
