import Problem from '../ProblemsWeSolve/Problem';

const WhatWeDo = ({ PhaseOne, PhaseTwo, PhaseThree, title }) => {
	return (
		<section className='join-us clearfix' id='what-we-do' style={{ marginTop: '100px' }}>
			<div className='container'>
				<div className='section-heading text-center'>
					<h2 data-aos='fade-up'>{title}</h2>
					<p data-aos='fade-up'>We want to disturb the way accelerator programs, education and wealth are made.</p>
					<br />
					<p data-aos='fade-up'>
						{' '}
						The days in which only a few people have access to real tech startups from early beginning, simple and easy to understand education are long gone. And so the underpaid
						jobs.
					</p>
					<br />
					<p data-aos='fade-up'>The Friends Token ecosystem is made up from 3 big phases.</p>
					<br />
				</div>
				<div style={{ textAlign: 'center', marginTop: '65px' }}>
					<h3>Phase 1</h3>
					<p>Create the new generations of investors</p>
				</div>
				<div className='row' style={{ marginTop: '0px' }}>
					{PhaseOne &&
						PhaseOne.map((item, key) => (
							<Problem key={key} ClassTop={item.ClassTop} ClassDown={item.ClassDown} ClassSteps={item.ClassSteps} Steps={item.Steps} title={item.title} text={item.text} />
						))}
				</div>
				<div style={{ textAlign: 'center', marginTop: '50px' }}>
					<h3>Phase 2</h3>
					<p>Build the learn to earn platform for investors and startup founders</p>
				</div>
				<div className='row'>
					{PhaseTwo &&
						PhaseTwo.map((item, key) => (
							<Problem key={key} ClassTop={item.ClassTop} ClassDown={item.ClassDown} ClassSteps={item.ClassSteps} Steps={item.Steps} title={item.title} text={item.text} />
						))}
				</div>
				<div style={{ textAlign: 'center', marginTop: '50px' }}>
					<h3>Phase 3</h3>
					<p>Create a bi-yearly accelerator program that will invest in the accepted startups in the exchange of equity</p>
				</div>
				<div className='row'>
					{PhaseThree &&
						PhaseThree.map((item, key) => (
							<Problem key={key} ClassTop={item.ClassTop} ClassDown={item.ClassDown} ClassSteps={item.ClassSteps} Steps={item.Steps} title={item.title} text={item.text} />
						))}
				</div>
			</div>
		</section>
	);
};

export default WhatWeDo;
