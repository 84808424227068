import './slider.scss';

export const SimpleSlider = () => {
	return (
		<div className='slider' style={{ marginTop: '100px' }}>
			<div className='slide-track'>
				<div className='slide'>Community token</div>
				<div className='slide'>Learn to eran platform</div>
				<div className='slide'>Startup accelerator program</div>
				<div className='slide'>Gouvernance token</div>
				<div className='slide'>NFT access</div>
				<div className='slide'>Wealth distribution</div>
				<div className='slide'>New unicorns</div>
			</div>
		</div>
	);
};
