import PromiseSectionHeading from './PromiseSectionHeading';
import { SimpleSlider } from './Slider';

const Promise = ({ title }) => {
	return (
		<section className='token-distribution' id='promise' style={{ marginTop: '70px' }}>
			<div className='container'>
				<PromiseSectionHeading title={title} />
			</div>
			<SimpleSlider />
		</section>
	);
};

export default Promise;
