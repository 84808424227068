const Problem = ({ ClassTop, ClassDown, title, text }) => {
	const noTitle = !title ? 'center-text service_single_content text-center fadeInUp' : 'service_single_content text-center fadeInUp';
	return (
		<div className={ClassTop}>
			<div className={ClassDown}>
				<div className={noTitle} data-wow-delay='0.2s'>
					{title && <h6>{title}</h6>}
					<p>{text}</p>
				</div>
			</div>
		</div>
	);
};

export default Problem;
