const PromiseSectionHeading = ({ title }) => {
	return (
		<div className='section-heading text-center'>
			<h2 data-aos='fade-up'>{title}</h2>
			<p data-aos='fade-up'>
				Unprecedented Growth Potential: Through our democratizing access to education and deflationary mechanism we want to create the next generation of investors and actively
				shape the next generation of unicorns.
			</p>
			<br />
			<p data-aos='fade-up'>We are here to democratize the way education is done and how people are having access to early investment opportunities.</p>
			<br />
			<p data-aos='fade-up'>We want to be the next Y Combinator, staying at the intersection of web2 and web3 infrastructure.</p>
			<br />
			<p data-aos='fade-up'>We are the wealth distributors.</p>
		</div>
	);
};

export default PromiseSectionHeading;
