const SectionHeading = ({ title, text }) => {
	return (
		<div className='section-heading text-center'>
			<h2 data-aos='fade-up'>{title}</h2>
			<p data-aos='fade-up'>{text}</p>
		</div>
	);
};

export default SectionHeading;
