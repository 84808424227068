import SectionHeading from './../SectionHeading';
import Problem from './Problem';

const ProblemsWeSolve = ({ OurJoinUsFirst, OurJoinUsSecond, text, title }) => {
	return (
		<section className='join-us clearfix' id='friends'>
			<div className='container'>
				<SectionHeading title={title} text={text} />
				<div className='row'>
					{OurJoinUsFirst &&
						OurJoinUsFirst.map((item, key) => (
							<Problem key={key} ClassTop={item.ClassTop} ClassDown={item.ClassDown} ClassSteps={item.ClassSteps} Steps={item.Steps} title={item.title} text={item.text} />
						))}
					{OurJoinUsSecond &&
						OurJoinUsSecond.map((item, key) => (
							<Problem key={key} ClassTop={item.ClassTop} ClassDown={item.ClassDown} ClassSteps={item.ClassSteps} Steps={item.Steps} title={item.title} text={item.text} />
						))}
				</div>
			</div>
		</section>
	);
};

export default ProblemsWeSolve;
