import { useEffect } from 'react';
import { loader } from '../../utils';
import { Helmet } from 'react-helmet';
import { MainLogo } from '../../data/data-layout/Header/data-Logo.js';

function PreloaderContainer({ Title }) {
	useEffect(() => {
		loader();
	}, []);

	return (
		<>
			<Helmet>
				<meta charSet='utf-8' />
				<meta name='robots' content='noindex, follow' />
				<meta name='description' content='Hope – Health &amp; Medical React JS Template' />
				<meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no' />
				<title>{Title}</title>
			</Helmet>
			<div id='preloader'>
				<div className={'preload-content'}>
					<div className='image-container'>
						<img width={'75px'} height={'75px'} src={MainLogo} alt={'logo'} />
					</div>
				</div>
			</div>
		</>
	);
}

export default PreloaderContainer;
