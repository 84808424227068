import SectionHeading from '../SectionHeading';
import { clsx } from 'clsx';

const Roadmap = ({ data, title, text }) => {
	return (
		<section className='roadmap' id='roadmap' style={{ marginTop: '70px' }}>
			<SectionHeading title={title} text={text} />
			<div className='container'>
				<div className='row'>
					<div className='col-md-12'>
						<div className='main-timeline'>
							{data &&
								data.map((item, index) => {
									return (
										<div className='timeline' key={index}>
											<div className='date-content'>
												<div className='date-outer'>
													<span className='date'>
														{' '}
														<span className='month'>{item.month}</span> <span className='year'>{item.year}</span>{' '}
													</span>
												</div>
											</div>
											<div className='timeline-content'>
												<h5 className='title'>{item.title}</h5>
												<ul className={clsx(`list-marked${index % 2 !== 0 ? '-odd' : ''}`)}>
													{item.list &&
														item.list.map((itemList, keyList) => (
															<li key={keyList} className='text-white'>
																<i className={clsx(`fa ${itemList.checked ? 'fa-check-square' : 'fa-square'}`)} />
																{itemList.text}
															</li>
														))}
												</ul>
											</div>
										</div>
									);
								})}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Roadmap;
