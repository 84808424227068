import OurJoinUsFirst from '../../data/data-containers/HomeDemo1/data-OurJoinUsFirst.json';
import OurJoinUsSecond from '../../data/data-containers/HomeDemo1/data-OurJoinUsSecond.json';
import PhaseOne from '../../data/data-containers/HomeDemo1/data-WhatWeDo-PhaseOne.json';
import PhaseThree from '../../data/data-containers/HomeDemo1/data-WhatWeDo-PhaseThree.json';
import PhaseTwo from '../../data/data-containers/HomeDemo1/data-WhatWeDo-PhaseTwo.json';
import TokenDistributionInfo from '../../data/data-containers/HomeDemo1/data-TokenDistributionInfo.json';
import RoadmapInfo from '../../data/data-containers/HomeDemo1/data-RoadmapInfo.json';
import { HomeDemo1About1 } from '../../utils/allImgs';
import './style/HomeDemo1.scss';

import Header from '../../layouts/Header';
import SecAbout from '../../components/SecAbout';
import TokenDistribution from '../../components/TokenDistribution';
import Roadmap from '../../components/Roadmap';
import Promise from '../../components/Promise';
import ProblemsWeSolve from '../../components/ProblemsWeSolve';
import FooterPages from '../../layouts/Footer/FooterPages';
import WhatWeDo from '../../components/WhatWeDo';

const HomeContainer = () => {
	return (
		<div>
			<Header Title='Friends token' />
			<div className='clearfix' />
			<SecAbout imgDwon={false} text='The next billion assets tech startup accelerator' img={HomeDemo1About1} />
			<ProblemsWeSolve title={'Problems we solve'} OurJoinUsFirst={OurJoinUsFirst} OurJoinUsSecond={OurJoinUsSecond} text={''} />
			<Promise title={'The Promise'} />
			<WhatWeDo title={'What we do'} PhaseOne={PhaseOne} PhaseTwo={PhaseTwo} PhaseThree={PhaseThree} />
			<Roadmap title={'Roadmap'} text={''} data={RoadmapInfo} />
			<div className='clearfix' />
			<TokenDistribution
				title={'Tokenomics and Statistics'}
				text={'We care about our community that’s why we have implemented whale measures and also we the founders have a vested period over 1 year.'}
				data={TokenDistributionInfo}
			/>
			<FooterPages />
		</div>
	);
};

export default HomeContainer;
