import Cats from './../../assets/img/background-cats.png';

const SecAbout = ({ imgDwon = false, text, img }) => {
	return (
		<>
			{imgDwon ? (
				<section className='about-us-area section-padding-100 clearfix'>
					<div className='container'>
						<div className='row align-items-center'>
							<div className='col-12 col-lg-6 offset-lg-0 col-md-12 no-padding-left'>
								<div className='welcome-meter' data-aos='fade-up' data-aos-delay='200'>
									<img draggable='false' src={img} alt='' />
								</div>
							</div>
							<div className='col-12 col-lg-6 offset-lg-0'>
								<div className='who-we-contant mt-s'>
									<h4 data-aos='fade-up' data-aos-delay='300'>
										{text}
									</h4>
									<p data-aos='fade-up' data-aos-delay='300'>
										Deflationary mechanism, learn to earn platform, the new generation of investors.
									</p>
									<a href='https://web.telegram.org/a/' target='_blank' className='btn more-btn  mt-30'>
										Read more
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>
			) : (
				<section className='about-us-area section-padding-100 clearfix'>
					<div className='container'>
						<div className='row align-items-center'>
							<div className='col-12 col-lg-6 offset-lg-0'>
								<div className='who-we-contant mt-s'>
									<h4 data-aos='fade-up' data-aos-delay='300'>
										The next billion assets tech startup accelerator.
									</h4>
									<p data-aos='fade-up' data-aos-delay='300'>
										Deflationary mechanism, learn to earn platform, the new generation of investors.
									</p>
									<span className={'heading-buttons'}>
										<a
											style={{
												fontWeight: 'bold',
												fontSize: '14px',
												boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
												borderColor: 'rgba(78, 19, 76, 0.498039)',
												background: '#ffa139',
												color: '#662964'
											}}
											href='https://t.me/friendstoken_io'
											target='_blank'
											className='btn more-btn  mt-30'>
											<span
												style={{
													display: 'flex',
													justifyContent: 'center'
												}}>
												Join Telegram
											</span>
										</a>
										<a
											style={{
												cursor: 'pointer',
												fontWeight: 'bold',
												fontSize: '14px',
												boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
												borderColor: 'rgba(78, 19, 76, 0.498039)',
												background: '#ffa139',
												color: '#662964'
											}}
											href='https://twitter.com/friendstoken_io'
											target='_blank'
											className='btn more-btn  mt-30'>
											<span
												style={{
													display: 'flex',
													justifyContent: 'center'
												}}>
												Join Twitter
											</span>
										</a>
										<a
											style={{
												cursor: 'pointer',
												fontWeight: 'bold',
												fontSize: '14px',
												boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
												borderColor: 'rgba(78, 19, 76, 0.498039)',
												background: '#ffa139',
												color: '#662964'
											}}
											href='https://docs.google.com/file/d/1Nm-etVvYxSd76LgW8u2uBRh6PM4KZXn2/edit?usp=docslist_api&filetype=msword'
											target='_blank'
											className='btn more-btn  mt-30'>
											<span
												style={{
													cursor: 'pointer',
													display: 'flex',
													justifyContent: 'center'
												}}>
												Read Whitepaper
											</span>
										</a>
									</span>
								</div>
							</div>
							<div className='col-12 col-lg-6 offset-lg-0 col-md-12 no-padding-left cats-img-container'>
								<div className='welcome-meter' data-aos='fade-up' data-aos-delay='200'>
									<img
										style={{
											borderRadius: '50%',
											boxShadow: 'rgba(0, 0, 0, 0.74) 0px 3px 21px',
											userSelect: 'none'
										}}
										draggable='false'
										src={Cats}
										alt=''
									/>
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
		</>
	);
};

export default SecAbout;
