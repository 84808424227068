import {
  FooterPattern,
  FooterLogo
} from '../../../utils/allImgs'

import SectionHeading from '../../../components/SectionHeading'

import '../Footer.scss'

import IcoName from '../../../data/data-layout/Footer/data-IcoName.json'
import TextFooter from '../../../data/data-layout/Footer/data-TextFooter.json'

const FooterPages = ({ClassSpanTitle = ''}) => {
  return (

    <footer style={{background:'#421041',  boxShadow: '0 0 20px rgba(0, 0, 0, .5)'}} className="footer-area bg-img mt-5">
      <div className="footer-area bg-img">
        <div className=" demo">
          <div className="container" style={{textAlign: 'center'}}>
            {/*<div className="row ">*/}
            {/*  <div className="col-12 col-lg-4 col-md-6">*/}
            {/*    <div className="footer-copywrite-info">*/}
            {/*      <div className="copywrite_text fadeInUp" data-wow-delay="0.2s">*/}
            {/*        <div className="footer-logo">*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*   */}
            {/*    </div>*/}
            {/*  </div>*/}

            {/*  {TextFooter && TextFooter.map((item , key) => (*/}
            {/*    <div key={key} className={item.classBlock}>*/}
            {/*      <div className="contact_info_area d-sm-flex justify-content-between">*/}
            {/*        <div className={item.classInfo} data-wow-delay="0.3s">*/}
            {/*          <h5>{item.title}</h5>*/}
            {/*          <a href><p>{item.text1}</p></a>*/}
            {/*          <a href><p>{item.text2}</p></a>*/}
            {/*          <a href><p>{item.text3}</p></a>*/}
            {/*          <a href><p>{item.text4}</p></a>*/}
            {/*          {item.text5 && <a href><p>{item.text5}</p></a>}*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  ))}*/}

            {/*</div>*/}
            <div style={{display: 'flex', justifyContent: 'center', gap: '10px'}}>
              <a style={{cursor: 'pointer'}} href="https://t.me/friendstoken_io" target="_blank"><p>Telegram</p></a>
              <a style={{cursor: 'pointer'}} href="https://twitter.com/friendstoken_io" target="_blank"><p>Twitter</p></a>
            </div>
            <div>
              <p style={{fontSize:'14px',margin:0}}>All Rights Reserved © 2024 Friendstoken.io</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default FooterPages