import { NavLink } from 'react-router-dom';

const SecHeader = ({ Logo, dropdownItem, MenuInfo }) => {
	return (
		<section id='about'>
			<nav className='navbar navbar-expand-lg navbar-white fixed-top' id='banner'>
				<div className='container' id='about'>
					<a className='navbar-brand' href='#'>
						<span>
							<img className={'header-logo'} draggable='false' src={Logo} alt='logo' />
						</span>
					</a>
					<button
						className='navbar-toggler'
						type='button'
						data-bs-toggle='collapse'
						data-bs-target='#navbarSupportedContent'
						aria-controls='navbarSupportedContent'
						aria-expanded='false'
						aria-label='Toggle navigation'>
						<span className='navbar-toggler-icon' />
					</button>
					<div className='collapse navbar-collapse' id='navbarSupportedContent'>
						<ul className='navbar-nav ml-auto'>
							<li className='nav-item dropdown'>
								<div className='dropdown-menu' aria-labelledby='navbarDropdown'>
									{dropdownItem &&
										dropdownItem.map((item, key) => (
											<NavLink key={key} className='dropdown-item' to={item.path}>
												{item.nameLink}
											</NavLink>
										))}
								</div>
							</li>
							{MenuInfo &&
								MenuInfo.map((item, key) => (
									<li className='nav-item' key={key}>
										<a className='nav-link' href={item.path}>
											{item.nameLink}
										</a>
									</li>
								))}
						</ul>
					</div>
				</div>
			</nav>
		</section>
	);
};

export default SecHeader;
