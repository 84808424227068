import { DeflationaryGraphic } from '../../utils/allImgs';
import SectionHeading from '../SectionHeading';

const TokenDistribution = ({ data, title, text }) => {
	return (
		<section className='token-distribution ' id='tokenomics' style={{ marginTop: '100px' }}>
			<div className='container'>
				<SectionHeading title={title} text={text} />
				<div className='row align-items-center distribution-card-row' style={{ marginTop: '20px' }}>
					<div className={'distribution-card-container'}>
						<h3 className='text-center mb-30' data-aos-delay='0.3s'>
							{' '}
							Allocation
						</h3>
						{data &&
							data.map((item, key) => (
								<div className='token-info' key={key}>
									<div className={item.ClassName}>
										<div className='token-icon'>{item.Num}%</div>
										<div className='token-descr'>{item.descr}</div>
									</div>
								</div>
							))}
					</div>
					<div>
						<img src={DeflationaryGraphic} alt={'graphic'} />
					</div>
				</div>
				<div className='section-heading text-center' style={{ marginTop: '20px' }}>
					<h3 className='text-center' data-aos-delay='0.3s'>
						Burning mechanism
					</h3>
					<p data-aos='fade-up'>Until 2028 we aim to burn 23.76 Trillions of tokens and increase the value of the token by 100x.</p>
				</div>
				<div className='section-heading text-center' style={{ marginTop: '40px' }}>
					<h3 className='text-center' data-aos-delay='0.3s'>
						Utility token
					</h3>
					<p data-aos='fade-up'>You will be able to invest in new and well selected tech startups.</p>
				</div>
			</div>
		</section>
	);
};

export default TokenDistribution;
